import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default async (token: string, referNumber: string, hospCode: string) => {
  try {
    const url = `${getApiUrl()}/v1/mophRefer/referData`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        referNumber,
        hospCode,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        error.response.data.message_th ||
          error.response.data.message ||
          "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"
      );
    }
    throw new Error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง");
  }
};
