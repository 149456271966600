import React from "react";
import { Box, Typography } from "@mui/material";
import { IAppointment } from "../../../types/refers";
import { ThaiDateTime } from "../../../functions/FuncDateTimes";

interface AppointmentsListProps {
  appointments?: IAppointment[];
}

const AppointmentDetail: React.FC<AppointmentsListProps> = ({
  appointments = [],
}) => {
  return (
    <>
      {appointments.map((appointment, index) => (
        <Box key={index} className="border-2 border-gray-200 my-2 rounded-md">
          <Box className="border-b-2 border-gray-100">
            <Typography
              variant="inherit"
              sx={{ p: 1 }}
              className="text-slate-700"
            >
              {`นัดหมาย ${index + 1}`}
            </Typography>
          </Box>
          <Box sx={{ p: 1 }} className="grid grid-cols-2">
            <Typography variant="inherit" className="text-slate-700">
              {`วันเวลานัด : ${ThaiDateTime(appointment.appointmentDateTime)}`}
            </Typography>
            <Typography variant="inherit" className="text-slate-700">
              {`จุดนัดหมาย : ${appointment.appointmentPoint}`}
            </Typography>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography variant="inherit" className="text-slate-700">
              {`หมายเหตุ : ${appointment.appointmentNotes}`}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default AppointmentDetail;
